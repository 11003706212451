
import Field from "mobile.controls.filter.Field.js";

export default class FieldDistinct extends Field {
    constructor(options) {
        super(options);

        if (!this.filter.dataObject.getFields(this.field)) {
            console.warn(`MobileFilter: Field does not exist in data object '${this.field}'`);
        }
    }

    loadStorage() {
        super.loadStorage();
        if (!Array.isArray(this.storage.values)) {
            this.storage.values = [];
        }
    }

    setTempValue(value) {
        this.tempValue = value;
    }

    applyTempValue() {
        if (this.tempValue) {
            this.storage.values = this.tempValue;
            this.filter.update();
            this.tempValue = null;
        }
    }

    setValues(values) {
        this.storage.values = values;
        this.saveStorage();
    }

    getFilterString() {
        if (!this.storage.values.length) {
            return "";
        }
        return `[${this.field}] IN (${this.storage.values.map(e => `'${e}'`).join(",")})`;
    }

    getFilterItems() {
        if (!this.storage.values.length) {
            return [];
        }
        return [
            {
                column: this.field,
                operator: "inlist",
                value: this.storage.values,
            },
        ];
    }

    getFilterText() {
        if (!this.storage.values.length) {
            return "";
        }

        let text = "";

        if (this.storage.values.length >= 2) {
            text = text + `(${this.storage.values.length}) `;
        }

        text = text + this.storage.values.join(", ");

        return text;
    }

    clear() {
        this.storage.values = [];
        this.saveStorage();
    }

    // to show a single pill inside the filter that you can cancel
    getPill() {
    }

    // to show multiple pills below search input that you can cancel
    getPills() {
        return this.storage.values.map(text => {
            return {
                text: text,
                click: () => {
                    this.filter.showFieldSheet = true;
                    this.filter.field = this;
                },
                close: () => {
                    this.storage.values = this.storage.values.filter(e => e !== text);
                    this.filter.update();
                },
            };
        });
    }
}
